<template>
  <div class="home">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress"/>
    </div>
    <div class="main">
      <div class="info">
        <div class="info-item">
          <span class="amount">{{ contractBaseInfo.maxInvestAmount - accountInfo.totalInvestment }}</span>
          <span class="subtitle">最大额度$</span>
          <div class="deposit-box">
            <span class="deposit-amount">{{ accountInfo.totalInvestment.toFixed(0) }}</span>
            <span class="deposit-subtitle">累计投资$</span>
          </div>
        </div>
        <div class="info-item">
          <span class="amount">{{ contractBaseInfo.minInvestAmount }}</span>
          <span class="subtitle">最小投资$</span>
          <div class="deposit-box">
            <span class="deposit-amount">{{ accountInfo.totalInvestIncome.toFixed(0) }}</span>
            <span class="deposit-subtitle">累计收益$</span>
          </div>
        </div>
      </div>
      <div class="token-box" v-if="bindFlag">
        <div class="left-box">
          <img src="../../assets/fbnq.png"/>
          <span class="quantity">{{ strUtils.sliceString(leader) }}</span>
        </div>
        <span style="color: gray;font-size: 16px;">领导人地址</span>
      </div>
      <div class="order">
        <div class="title">资金托管分润系统</div>
        <div class="quantity-box">
          <input v-model="investAmount" type="number" :placeholder="investPlaceholder">
          <div class="right-box">
            <img src="../../assets/usdt.png"/>
            <span class="coin-name">USDT</span>
            <span class="button-all" @click="handleInvestAll">全部</span>
          </div>
        </div>
        <div class="invest-info-box">
          <div class="info-item">
            <span class="name">可用余额：</span>
            <span class="amount">{{ walletTokenBalance.toFixed(2) }}USDT</span>
          </div>
          <div class="info-item">
            <span class="name">当前价格：</span>
            <span class="amount">$1.00</span>
          </div>
        </div>
        <div class="invest-info-box">
          <div class="info-item">
            <span class="name">投资人数：</span>
            <span class="amount">{{ contractBaseInfo.investMembers }}</span>
          </div>
          <div class="info-item">
            <span class="name">投资次数：</span>
            <span class="amount">{{ accountInfo.investTimes }}</span>
          </div>
        </div>
        <div class="submit">
          <van-button v-if="allowance < 500"
                      :loading="allowanceLoading"
                      loading-text="授权中..."
                      color="#ffb516"
                      block
                      size="large" @click="approveTokens">授权
          </van-button>
          <van-button v-else
                      :loading="investLoading"
                      loading-text="投资中..."
                      color="#ffb516"
                      block
                      size="large"
                      @click="createOrder">创建账户
          </van-button>
        </div>
      </div>
      <span class="title">投资详情</span>
      <div class="account" v-for="(order,index) in orders" :key="index">
        <div class="income-box">
          <img src="../../assets/usdt.png" alt=""/>
          <div class="right-box">
            <span style="font-weight: bold;">{{ order.income.toFixed(4) }}</span>
            <span style="font-size: 14px;">投资收益</span>
          </div>
        </div>
        <div class="income-item">
          <span class="name">投资数量</span>
          <span class="values">{{ order.amount.toFixed(4) }}</span>
        </div>
        <div class="income-item">
          <span class="name">已提收益</span>
          <span class="values">{{ order.extractedIncome.toFixed(4) }}</span>
        </div>
        <div class="income-item">
          <span class="name">投资期限</span>
          <span class="values">90天</span>
        </div>
        <div class="income-item">
          <span class="name">开始时间</span>
          <span class="values">{{ DateTimeUtils.formatTimestamp(order.investTime) }}</span>
        </div>
        <div class="income-item">
          <span class="name">到期时间</span>
          <span class="values">{{ DateTimeUtils.formatTimestamp(order.expireTime) }}</span>
        </div>
        <div class="withdraw-btn">
          <van-button
              :loading="withdrawLoading"
              loading-text="提取中..."
              color="#ffb516"
              size="large"
              block
              @click="withdrawInvestIncome(order.orderNo,order.income - order.extractedIncome - 1)">提取收益
          </van-button>
        </div>
        <div class="withdraw-btn">
          <van-button
              v-if="order.expireTime <= getCurrentTimestamp() && order.amount > 0"
              :loading="withdrawPrincipalLoading"
              loading-text="提取中..."
              color="#ffb516"
              size="large"
              block
              @click="withdrawPrincipal(order.orderNo)">提取本金
          </van-button>
        </div>
      </div>
    </div>
    <van-dialog
        v-model:show="bindDialogShow"
        title="绑定领导人"
        show-cancel-button
        @confirm="handleBindConfirm">
      <van-cell-group inset>
        <van-field
            v-model="leaderAddress"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入领导人钱包地址"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script setup>
import NavBar from "@/components/NavBar.vue";
import {onMounted, ref} from "vue";
import Web3 from "web3";
import {
  stakingContractABI,
  stakingContractAddress,
  tokenContractABI,
  tokenContractAddress
} from "@/assets/chain/contractDetails";
import {DateTimeUtils} from "@/utils/DateTimeUtils";
import WalletUtils from "@/utils/WalletUtils";
import {closeToast, showConfirmDialog, showFailToast, showLoadingToast, showSuccessToast, showToast} from "vant";
import {useRoute} from "vue-router";
import StringUtils from "@/utils/StringUtils";

const route = useRoute();
const strUtils = ref(new StringUtils());
const walletAddress = ref(undefined);
const walletTokenBalance = ref(0);
const allowance = ref(0);
const contractBaseInfo = ref({
  investMembers: 0,
  leaderCounts: 0,
  minInvestAmount: 0,
  maxInvestAmount: 0
})
const accountInfo = ref({
  totalInvestment: 0,
  totalInvestIncome: 0,
  totalReferIncome: 0,
  extractReferIncome: 0,
  totalTokenAmount: 0,
  investTimes: 0,
  exist: false
})

const orders = ref([]);
const investPlaceholder = ref(undefined);

const investAmount = ref(undefined);
const allowanceLoading = ref(false);
const investLoading = ref(false);

let stakingContractInstance = undefined;
let tokenContractInstance = undefined;
let web3 = undefined;
const initWeb3 = (async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
    tokenContractInstance = new window.web3.eth.Contract(tokenContractABI, tokenContractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
});

const getAddress = (async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
});

const getTokenBalance = (async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    const balance = await walletUtils.getTokenBalance(tokenContractAddress, walletAddress.value);
    walletTokenBalance.value = Number(balance);
  } else {
    console.error("获取钱包余额失败");
  }
});

const getContractBaseInfo = (async () => {
  try {
    const investMembers = await stakingContractInstance.methods.investMembers().call();
    const leaderCounts = await stakingContractInstance.methods.leaderCounts().call();
    const minInvestAmount = await stakingContractInstance.methods.minInvestAmount().call();
    const maxInvestAmount = await stakingContractInstance.methods.maxInvestAmount().call();
    contractBaseInfo.value.investMembers = Number(investMembers);
    contractBaseInfo.value.leaderCounts = Number(leaderCounts);
    contractBaseInfo.value.minInvestAmount = Number(web3.utils.fromWei(minInvestAmount, 'ether'));
    contractBaseInfo.value.maxInvestAmount = Number(web3.utils.fromWei(maxInvestAmount, 'ether'));
  } catch (error) {
    console.error('获取合约基本信息失败：', error.messages);
  }
});

const getAccountInfo = (async () => {
  try {
    const account = await stakingContractInstance.methods.accounts(walletAddress.value).call();
    accountInfo.value.exist = account.exist;
    accountInfo.value.totalInvestment = Number(web3.utils.fromWei(account.totalInvestment, 'ether'));
    accountInfo.value.totalInvestIncome = Number(web3.utils.fromWei(account.totalInvestIncome, 'ether'));
    accountInfo.value.totalReferIncome = Number(web3.utils.fromWei(account.totalReferIncome, 'ether'));
    accountInfo.value.extractReferIncome = Number(web3.utils.fromWei(account.extractReferIncome, 'ether'));
    accountInfo.value.totalTokenAmount = Number(web3.utils.fromWei(account.totalTokenAmount, 'ether'));
    accountInfo.value.investTimes = Number(account.investTimes);
  } catch (error) {
    console.error('获取合约基本信息失败：', error.messages);
  }
});

const withdrawLoading = ref(false);
const withdrawInvestIncome = async (orderNo, amount) => {
  if (Number(amount) <= 0) {
    showToast('提取数量必须大于0');
    return;
  }
  try {
    withdrawLoading.value = true;
    await stakingContractInstance.methods.withdrawInvestIncome(orderNo, web3.utils.toWei(amount, 'ether'))
        .send({from: walletAddress.value});
    withdrawLoading.value = false;
    showSuccessToast("提取成功");
  } catch (error) {
    console.log(error)
    withdrawLoading.value = false;
    showFailToast("提取失败");
  }
}

const withdrawPrincipalLoading = ref(false)

const withdrawPrincipal = async (orderNo) => {
  try {
    withdrawPrincipalLoading.value = true;
    await stakingContractInstance.methods.withdrawPrincipal(orderNo).send({from: walletAddress.value});
    withdrawPrincipalLoading.value = false;
    showSuccessToast("提取成功");
  } catch (error) {
    console.log(error)
    withdrawPrincipalLoading.value = false;
    showFailToast("提取失败");
  }
}

const getCurrentTimestamp = () => {
  return Math.floor(Date.now() / 1000);
}

const getUserOrders = (async () => {
  try {
    orders.value = []
    const orderNos = await stakingContractInstance.methods.getUserOrders(walletAddress.value).call();
    console.log('历史订单号：', orderNos)
    for (const orderNo of orderNos) {
      const order = await stakingContractInstance.methods.orders(orderNo).call();
      console.log(orderNo + '的订单详情：', order)
      let income = 0;
      if (orderNo == 1717244993000000) {
        income = Number(web3.utils.fromWei(order.income, 'ether')) - 990;
      } else {
        income = Number(web3.utils.fromWei(order.income, 'ether'));
      }
      const detail = {
        user: order.user,
        orderNo: order.orderNo,
        amount: Number(web3.utils.fromWei(order.amount, 'ether')),
        income: income,
        extractedIncome: Number(web3.utils.fromWei(order.extractedIncome, 'ether')),
        duration: Number(order.duration),
        investTime: Number(order.investTime),
        expireTime: Number(order.expireTime),
        exist: order.exist
      }
      orders.value.push(detail);
    }
  } catch (error) {
    console.error('获取历史订单号失败：', error);
  }
});

const updateInvestPlaceholder = (() => {
  investPlaceholder.value = '最大可输入 ' + String(contractBaseInfo.value.maxInvestAmount - accountInfo.value.totalInvestment);
});

const createOrder = (async () => {
  try {
    await updateAllowance();
    if (investAmount.value > walletTokenBalance.value) {
      showToast("钱包余额不足");
      return;
    }
    if (investAmount.value < contractBaseInfo.value.minInvestAmount) {
      showToast("小于最小投资数量");
      return;
    }
    if (investAmount.value > allowance.value) {
      showConfirmDialog({
        message: '当前授权转账余额不足，是否继续授权？'
      }).then(async () => {
        await approveProcess();
      }).catch(() => {
        showToast('取消交易')
      })
    } else {
      investLoading.value = true
      await stakingContractInstance.methods.createOrder(web3.utils.toWei(investAmount.value, 'ether'))
          .send({from: walletAddress.value});
      investLoading.value = false;
      showSuccessToast("投资成功");
      await updateAllowance();
      await getAccountInfo();
      await getUserOrders();
    }
  } catch (error) {
    investLoading.value = false;
    if (error.code === 4001) {
      showFailToast("取消投资");
      return;
    }
    if (error.code === -32603) {
      showFailToast("投资失败");
      return;
    }
    showFailToast("投资失败");
  }
});

const handleInvestAll = (() => {
  if (walletTokenBalance.value === 0) {
    showFailToast("钱包余额为0，请先充值！");
    return;
  }
  if (walletTokenBalance.value > contractBaseInfo.value.maxInvestAmount - accountInfo.value.totalInvestment) {
    investAmount.value = contractBaseInfo.value.maxInvestAmount - accountInfo.value.totalInvestment;
  } else {
    investAmount.value = walletTokenBalance.value;
  }
});

const bindFlag = ref(false);
const leader = ref('');
const checkBindStatus = (async () => {
  try {
    bindFlag.value = await stakingContractInstance.methods.checkBindStatus(walletAddress.value).call();
    console.log("Bind status is :", bindFlag.value)
    if (bindFlag.value) {
      leader.value = await stakingContractInstance.methods.referrers(walletAddress.value).call();
      console.log("Leader address is :", leader.value);
    }
  } catch (error) {
    bindFlag.value = false;
  }
})

const updateAllowance = (async () => {
  const res = await tokenContractInstance.methods
      .allowance(walletAddress.value, stakingContractInstance.options.address)
      .call();
  allowance.value = web3.utils.fromWei(res, "ether");
});

const approveTokens = (async () => {
  try {
    allowanceLoading.value = true;
    await tokenContractInstance.methods
        .approve(stakingContractInstance.options.address, web3.utils.toWei('30000', "ether"))
        .send({from: walletAddress.value});
    await updateAllowance();
    allowanceLoading.value = false;
    showSuccessToast("授权成功");
  } catch (error) {
    allowanceLoading.value = false;
    if (error.code === 4001) {
      showFailToast("拒绝授权");
      return;
    }
    if (error.code === -32603) {
      showFailToast("授权失败");
      return;
    }
    showFailToast("授权失败");
  }
})

const approveProcess = (async () => {
  try {
    showLoadingToast({
      message: '授权中...',
      forbidClick: true,
      duration: 0
    })
    await tokenContractInstance.methods
        .approve(stakingContractInstance.options.address, web3.utils.toWei('30000', "ether"))
        .send({from: walletAddress.value});
    await updateAllowance();
    closeToast();
    showSuccessToast("授权成功");
  } catch (error) {
    closeToast();
    if (error.code === 4001) {
      showFailToast("拒绝授权");
      return;
    }
    if (error.code === -32603) {
      showFailToast("授权失败");
      return;
    }
    showFailToast("授权失败");

  }
})

const leaderAddress = ref(undefined);
const bindDialogShow = ref(false);
const checkBind = (async () => {
  const inviteAddress = route.query.inviteAddress;
  if (inviteAddress) {
    leaderAddress.value = inviteAddress;
    const res = await stakingContractInstance.methods.checkBindStatus(walletAddress.value).call();
    if (res) {
      return;
    }
    bindDialogShow.value = true;
  }
});

const handleBindConfirm = (async () => {
  try {
    if (!leaderAddress.value && !web3.utils.isAddress(leaderAddress.value)) {
      showFailToast("请输入正确的钱包地址");
      return;
    }
    showLoadingToast({
      message: '绑定中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    });
    await stakingContractInstance.methods.bindLeader(leaderAddress.value).send({from: walletAddress.value});
    closeToast();
    showSuccessToast("绑定成功");
    await checkBindStatus();
  } catch (error) {
    closeToast();
    if (error.code === 4001) {
      showFailToast("取消绑定");
      return;
    }
    if (error.code === -32603) {
      showFailToast("绑定失败");
      return;
    }
    showFailToast("绑定失败");
  }
})

onMounted(async () => {
  await initWeb3();
  await getAddress();
  await checkBindStatus();
  await getTokenBalance();
  await updateAllowance();
  await getContractBaseInfo();
  await getAccountInfo();
  await getUserOrders();
  await checkBind();
  updateInvestPlaceholder();
})
</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(247, 247, 247, 0.77);

  .nav {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 0;
    padding: 10px 18px 20px 18px;
    overflow: auto;

    .info {
      width: 100%;
      padding: 10px 20px 20px 20px;
      background-color: #ffb516;
      display: flex;
      flex-direction: row;
      border-radius: 12px;
      box-sizing: border-box;
      justify-content: space-between;

      .info-item {
        width: 47%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .amount {
          padding: 10px 0 0 0;
          box-sizing: border-box;
          font-size: 30px;
          color: white;
          font-weight: bold;
        }

        .subtitle {
          padding: 5px;
          box-sizing: border-box;
          font-size: 16px;
          color: white;
          font-weight: bold;
        }

        .deposit-box {
          width: 100%;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          border-radius: 12px;
          align-items: center;
          background-color: rgba(252, 243, 218, 0.77);

          .deposit-amount {
            font-size: 30px;
            color: #ffb516;
            font-weight: bold;
          }

          .deposit-subtitle {
            padding: 5px;
            box-sizing: border-box;
            font-size: 16px;
            color: #4b4b4b;
            font-weight: bold;
          }
        }
      }
    }

    .token-box {
      margin-top: 13px;
      width: 100%;
      height: 50px;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #b0b0b0;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: white;
      align-items: center;

      .left-box {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: white;
          box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75);
        }

        .quantity {
          margin-left: 10px;
          color: black;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .order {
      width: 100%;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 12px;
      border: 1px solid #b0b0b0;
      background-color: white;

      .title {
        display: flex;
        width: 100%;
        align-items: flex-start;
      }

      .quantity-box {
        margin: 10px 0;
        width: 100%;
        height: 50px;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #b0b0b0;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        input {
          width: 60%;
          height: 30px;
          border: none;
          background: none;
        }

        .right-box {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
          }

          .coin-name {
            margin-left: 5px;
            font-size: 14px;
            color: black;
          }

          .button-all {
            margin-left: 10px;
            color: #ffb516;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }

      .invest-info-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 2px 0;
        justify-content: space-between;

        .info-item {
          display: flex;
          flex-direction: row;

          .name {
            font-size: 14px;
            color: gray;
          }

          .amount {
            font-size: 14px;
            color: #ffb516;
          }
        }
      }

      .submit {
        margin-top: 10px;
        width: 100%;
      }
    }

    .title {
      padding: 15px 0;
      color: black;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
    }

    .account {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 12px;
      border: 1px solid #b0b0b0;
      background-color: white;

      .income-box {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 6px;
        background-color: rgba(247, 247, 247, 0.77);
        border: 1px solid #b0b0b0;
        margin-bottom: 10px;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          display: block;
        }

        .right-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: black;
          justify-content: space-around;
        }
      }

      .income-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 0;
        box-sizing: border-box;

        .name {
          font-size: 16px;
          font-weight: bold;
          color: gray;
        }

        .values {
          font-size: 16px;
          color: black;
        }
      }

      .withdraw-btn {
        width: 100%;
        padding: 10px 0 0 0;
      }
    }
  }
}
</style>
